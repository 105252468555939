import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import ActionCreator from "../ActionCreator";
import Selectors from "../Selectors";
import * as Ant from "antd";
const queryString = require("query-string");
import * as Cart from "../Contexts/CartContext";
import { navigate } from "gatsby";
import { Context } from "../AppContext";

function AuthResultPage({ appActions, profile, location }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const app = useContext(Context);

  useEffect(() => {
    (async () => {
      try {
        if (typeof window !== "undefined") {
          const queryParams = queryString.parse(location.search);
          window.localStorage.setItem("token", queryParams.token);
          await app.actions.autoLogin();
          // TODO: deprecated
          let result = await appActions.autoLogin({
            setToken: app.actions.setToken,
          });
          Cart.Actions.fetchCart();

          if (result) {
            navigate("/profile");
            return;
          }
        }
      } catch (ex) {
        console.warn(ex);
      }

      setError("登入失敗，請洽客服");
      setLoading(false);
    })();
  }, [app.actions, appActions]);

  return (
    <div>
      {loading && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            padding: 20,
          }}
        >
          <Ant.Spin />
          <div style={{ marginLeft: 10 }}>嘗試登入中，請稍候...</div>
        </div>
      )}

      {error && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            padding: 20,
          }}
        >
          <div style={{ marginLeft: 10 }}>{error}</div>
        </div>
      )}
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(AuthResultPage);
